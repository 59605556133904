import React from 'react';
import {Form} from 'react-final-form';
import {
  TextField,
} from 'mui-rff';
import {
  Typography,
  Paper,
  Grid,
  Button,
  CssBaseline,
} from '@material-ui/core';
import RentValidator from './validators/rent-validator';
import ConnectedButton from '../../avalaunch-web3/ConnectedButton'

const onSubmit = async values => {
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  await sleep(300);
  window.alert(JSON.stringify(values, 0, 2));
};

export default ({match: {path}}) => {
  const id = path.split('/')[2];
  return (
    <div style={{padding: 16, margin: 'auto', maxWidth: 600}}>
      <CssBaseline/>
      <Typography variant="h4" align="center" component="h1" gutterBottom>
        <span role="img" aria-label="flag">
          🏁
        </span>{' '}
        Rent Block {id}
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={{employed: true, stooge: 'larry'}}
        validate={RentValidator}
        render={({handleSubmit, form, submitting, pristine, values}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Paper style={{padding: 16}}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} key={0}>
                  <TextField
                    label="Days"
                    name="days"
                    margin="none"
                    required={true}
                  />
                </Grid>
                <Grid item style={{marginTop: 16}}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={form.reset}
                    disabled={submitting || pristine}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item style={{marginTop: 16}}>
                  <ConnectedButton calltoactionmessage='Connect your Wallet to Submit'>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </Button>
                </ConnectedButton>
                </Grid>
              </Grid>
            </Paper>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </div>
  );
}
