import { Contract } from '@ethersproject/contracts'
import { useActiveWeb3React } from '../../hooks/index'

// AVALAUNCH
import { abi as STORE_FORM_ABI } from '../json/StoreForm.json'
import {STORE_FORM_ADDRESS} from '../constants'

import {useContract} from '../../hooks/useContract'

// Avalaunch
export function useStoreFormContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? STORE_FORM_ADDRESS[chainId] : undefined, STORE_FORM_ABI, true)
}
