/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react'
import Hero from "./landing/Hero";
import Features1 from "./landing/Features1";
import Governance from "./landing/Governance";

export default function Example() {
  return (
    <Fragment>
      <Hero/>
      <Features1/>
      <Governance/>
    </Fragment>
  )
}
