import React from 'react';
import {useWeb3React} from '@web3-react/core'
import {injected} from "./connectors";
import {Button} from '@material-ui/core';
import { useActiveWeb3React } from '../hooks'

export default (props) => {
  const context = useActiveWeb3React();
  const { connector,
    library,
    //chainId,
    //account,
    activate,
    // deactivate,
    // active,
    // error
  } = context;

  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);
  console.log("!!(library): ", !!(library))
  console.log("!library: ", !(library))
  console.log("(library): ", library)
  console.log("V}connector", connector)
  console.log("injected: ", injected)
  return (
    <div>

    { // When no Connctor is activated
      (!!(library) === false) &&
      (
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
                    {setActivatingConnector(injected);
                    activate(injected);}
                  }
          >
          {props.calltoactionmessage}
        </Button>
      )
    }

    { // When the Injected Connctor is activated,
      // shows the desired componet

      (!!(library) === true) &&
      (connector == injected) &&
      (
        props.children
      )
    }
  </div>
);
}
