import { TransactionResponse } from '@ethersproject/providers'
import { useActiveWeb3React } from '../../hooks'
import { useAirdropContract } from '../../hooks/useContract'
import { calculateGasMargin } from '../../utils'
import { useTransactionAdder } from '../../state/transactions/hooks'
import {JSBI} from '@pangolindex/sdk'
import { useSingleCallResult } from '../../state/multicall/hooks'

// AVALAUNCH
import { useStoreFormContract } from './useContract'

export function useAirdropIsClaimingAllowed(): boolean {
	const airdropContract = useAirdropContract()
	const claimingAllowedResult = useSingleCallResult(airdropContract, 'claimingAllowed', [])
	return Boolean(!claimingAllowedResult.loading && claimingAllowedResult.result !== undefined && claimingAllowedResult.result[0] === true)
}

export function useUserHasAvailableClaim(account: string | null | undefined): boolean {
	const airdropContract = useAirdropContract()
	const withdrawAmountResult = useSingleCallResult(airdropContract, 'withdrawAmount', [account ? account : undefined])
	return Boolean(account && !withdrawAmountResult.loading && withdrawAmountResult.result !== undefined && !JSBI.equal(JSBI.BigInt(withdrawAmountResult.result?.[0]), JSBI.BigInt(0)))
}

export function useStoreCallback(): {
	storeCallback: (_title: string,
	_imageURL: string,
	_description: string,
	_buyPrice: number,
	_rentPrice: number) => Promise<any>
} {
	const { library, chainId } = useActiveWeb3React()

	const addTransaction = useTransactionAdder()
	const storeFormContract = useStoreFormContract()

	const storeCallback = async function (_title: string,
	_imageURL: string,
	_description: string,
	_buyPrice: number,
	_rentPrice: number) {
		if (!library || !chainId || !storeFormContract) return

		return storeFormContract.estimateGas['store'](_title,_imageURL,
							_description,_buyPrice,_rentPrice).then(estimatedGasLimit => {
			return storeFormContract
				.store(_title,
							_imageURL,
							_description,
							_buyPrice,
							_rentPrice,
					{ value: null, gasLimit: calculateGasMargin(estimatedGasLimit) })
				.then((response: TransactionResponse) => {
					addTransaction(response, {
						summary: `Claimed PNG`,
						claim: { recipient: 'myaccount' }
					})
					return response.hash
				})
				// .catch((error: any) => {
				// 	console.log(error)
				// 	return ""
				// })
				// Handling error in the front end
		})
	}

	return { storeCallback }
}
